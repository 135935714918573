import { ITEM_DRESSING_ACUMATICA_LABEL, ITEM_DRESSING_CUSTOM_LABEL } from '../models';

function transformMembers(
    members,
    shouldSkipFrozen,
    shouldSkipZeroPrice,
    shouldSkipActiveStatusCheck
) {
    return members.reduce((acc, member) => {
        if (
            member.InventoryID.value === undefined ||
            (shouldSkipActiveStatusCheck ? false : member.ItemStatus?.value !== 'Active') || // kind of a weird check, I know, but it's because we're basically doing a negating check - we should ignore the status check altogether, considering the value false and letting the other conditions decide what we should do
            member.ItemPrice?.value === undefined ||
            member.Description?.value === undefined ||
            member.ItemClass?.value?.toUpperCase() === 'SERVICES' ||
            (shouldSkipZeroPrice && member.ItemPrice.value === 0)
        ) {
            return acc;
        }

        if (shouldSkipFrozen && member.ItemIsFrozen && member.ItemIsFrozen.value) {
            return acc;
        }

        let shortDesc = member.ItemShortDesc?.value;
        if (shortDesc !== undefined && member.ItemShortAppend?.value !== undefined) {
            shortDesc += ` (${member.ItemShortAppend.value})`;
        }
        acc[member.InventoryID.value] = {
            description: member.Description.value,
            inventoryId: member.InventoryID.value,
            price: member.ItemPrice.value,
            shortDescription: shortDesc ?? member.Description.value,
        };
        return acc;
    }, {});
}

function transformFinishMembers(
    groupedByParent,
    parentCategoryId,
    shouldSkipFrozen,
    shouldSkipZeroPrice,
    shouldSkipActiveStatusCheck
) {
    return groupedByParent[parentCategoryId].reduce((acc, product) => {
        const members = transformMembers(
            product.Members,
            shouldSkipFrozen,
            shouldSkipZeroPrice,
            shouldSkipActiveStatusCheck
        );
        if (Object.keys(members).length > 0) {
            acc[product.Description.value] = members;
        } else {
            acc[product.Description.value] = null;
        }
        return acc;
    }, {});
}

function buildTreeRecursive(
    groupedByParent,
    parentId,
    shouldSkipFrozen = false,
    shouldSkipZeroPrice = false,
    shouldSkipActiveStatusCheck = true
) {
    if (!groupedByParent[parentId]) {
        return null;
    }

    if (
        groupedByParent[parentId].length > 0 &&
        ['Wet', 'Dry'].includes(groupedByParent[parentId][0].Description.value)
    ) {
        return transformFinishMembers(
            groupedByParent,
            parentId,
            shouldSkipFrozen,
            shouldSkipZeroPrice,
            shouldSkipActiveStatusCheck
        );
    }

    return groupedByParent[parentId].reduce((acc, product) => {
        if (product.Description.value?.toUpperCase() === 'SERVICES') {
            return acc;
        }

        const children = buildTreeRecursive(
            groupedByParent,
            product.CategoryID.value,
            shouldSkipFrozen,
            shouldSkipZeroPrice,
            shouldSkipActiveStatusCheck
        );
        acc[product.Description.value] = children;
        return acc;
    }, {});
}

function cleanTree(parsedProducts) {
    const newParsedProducts = parsedProducts;
    Object.keys(newParsedProducts).forEach(specie => {
        Object.keys(newParsedProducts[specie]).forEach(tanningType => {
            Object.keys(newParsedProducts[specie][tanningType]).forEach(prepType => {
                Object.keys(newParsedProducts[specie][tanningType][prepType]).forEach(
                    finishType => {
                        if (
                            newParsedProducts[specie][tanningType][prepType][finishType] === null ||
                            Object.keys(
                                newParsedProducts[specie][tanningType][prepType][finishType]
                            ).length === 0
                        ) {
                            delete newParsedProducts[specie][tanningType][prepType][finishType];
                        }
                    }
                );
                if (
                    newParsedProducts[specie][tanningType][prepType] === null ||
                    Object.keys(newParsedProducts[specie][tanningType][prepType]).length === 0
                ) {
                    delete newParsedProducts[specie][tanningType][prepType];
                }
            });
            if (
                newParsedProducts[specie][tanningType] === null ||
                Object.keys(newParsedProducts[specie][tanningType]).length === 0
            ) {
                delete newParsedProducts[specie][tanningType];
            }
        });
        if (
            newParsedProducts[specie] === null ||
            Object.keys(newParsedProducts[specie]).length === 0
        ) {
            delete newParsedProducts[specie];
        }
    });
    return newParsedProducts;
}

function parseProducts(
    products,
    shouldSkipFrozen = false,
    shouldSkipZeroPrice = false,
    shouldSkipActiveStatusCheck = false
) {
    const groupedByParent = products.reduce((acc, product) => {
        const parentCategoryId = product.ParentCategoryID?.value;
        if (parentCategoryId === undefined) {
            return acc;
        }
        if (!acc[parentCategoryId]) {
            acc[parentCategoryId] = [];
        }
        acc[parentCategoryId].push(product);
        return acc;
    }, {});
    return cleanTree(
        buildTreeRecursive(
            groupedByParent,
            '0',
            shouldSkipFrozen,
            shouldSkipZeroPrice,
            shouldSkipActiveStatusCheck
        )
    );
}

function toValueAndLabel(value) {
    return { value, label: value };
}

function getSpecies(parsedProducts) {
    return Object.keys(parsedProducts)
        .map(toValueAndLabel)
        .sort((a, b) => a.label.localeCompare(b.label));
}

function getTanningTypeName(tanningType) {
    if (tanningType === ITEM_DRESSING_ACUMATICA_LABEL) {
        return ITEM_DRESSING_CUSTOM_LABEL;
    }
    return tanningType;
}

function getTanningTypes(parsedProducts, specie) {
    return (
        specie &&
        Object.keys(parsedProducts[specie])
            .map(t => {
                return {
                    value: t,
                    label: getTanningTypeName(t),
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label))
    );
}

function getPrepTypeName(preparationType) {
    if (preparationType === false || preparationType === undefined) {
        return 'Salted';
    }
    if (preparationType === true) {
        return 'Frozen';
    }
    return preparationType;
}

function getFinishName(finishType) {
    if (finishType === false || finishType === undefined) {
        return 'Wet';
    }
    if (finishType === true) {
        return 'Dry';
    }
    return finishType;
}

function getPreparationTypes(parsedProducts, specie, tanningType) {
    return (
        specie &&
        tanningType &&
        Object.keys(parsedProducts[specie][tanningType])
            .map(toValueAndLabel)
            .sort((a, b) => a.label.localeCompare(b.label))
    );
}

function getFinishes(parsedProducts, specie, tanningType, preparationType) {
    return (
        specie &&
        tanningType &&
        preparationType !== undefined &&
        Object.keys(parsedProducts[specie][tanningType][getPrepTypeName(preparationType)])
            .map(toValueAndLabel)
            .sort((a, b) => a.label.localeCompare(b.label))
    );
}

function getSpeciesItem(
    parsedProducts,
    specie,
    tanningType,
    preparationType,
    finishType,
    specieItem
) {
    return parsedProducts[specie][tanningType][getPrepTypeName(preparationType)][
        getFinishName(finishType)
    ][specieItem];
}

function getSpeciesItems(parsedProducts, specie, tanningType, preparationType, finishType) {
    const items = [];
    if (
        specie !== undefined &&
        tanningType !== undefined &&
        preparationType !== undefined &&
        finishType !== undefined
    ) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [k, v] of Object.entries(
            parsedProducts[specie][tanningType][getPrepTypeName(preparationType)][
                getFinishName(finishType)
            ]
        )) {
            items.push({ value: k, label: v.shortDescription });
        }
    }
    return items.sort((a, b) => a.label.localeCompare(b.label));
}

function getSpeciesItemInventoryId(
    parsedProducts,
    specie,
    tanningType,
    preparationType,
    finish,
    specieItem
) {
    const prepType = getPrepTypeName(preparationType);
    const finishName = getFinishName(finish);
    return parsedProducts[specie][tanningType][prepType][finishName][specieItem].inventoryId;
}

function parseProductsForReverseInventoryIdLookup(products) {
    return products.reduce((acc, product) => {
        product.Members.forEach(member => {
            if (member.InventoryID.value !== undefined) {
                const segments = product.Path.value.split('/');
                let shortDesc = member.ItemShortDesc?.value;
                if (shortDesc !== undefined && member.ItemShortAppend?.value !== undefined) {
                    shortDesc += ` (${member.ItemShortAppend.value})`;
                }
                acc[member.InventoryID.value] = {
                    specie: segments[1],
                    tanningType: segments[2],
                    prepType: segments[3],
                    finishType: segments[4],
                    description: member.Description.value,
                    itemPrice: member.ItemPrice.value,
                    shortDescription: shortDesc ?? member.Description.value,
                    // itemShortDescription: member.ItemShortDesc.value,
                };
            }
        });

        return acc;
    }, {});
}

function parseProductsForReversePrepTypeLookup(products) {
    const saltedProducts = [];
    const frozenProducts = [];
    return products.reduce((acc, product) => {
        product.Members.forEach(member => {
            if (member.InventoryID.value !== undefined) {
                const segments = product.Path.value.split('/');
                const currProduct = {
                    inventoryId: member.InventoryID.value,
                    specieItem: member.Description.value,
                    // itemShortDescription : member.ItemShortDesc.value,
                    specie: segments[1],
                    tanningType: segments[3],
                    prepType: segments[5],
                    finishType: segments[7],
                    description: member.Description.value,
                    itemPrice: member.ItemPrice.value,
                };
                if (segments[5] === 'Salted') {
                    // Would be Dry
                    saltedProducts.push(currProduct);
                }
                if (segments[5] === 'Frozen') {
                    frozenProducts.push(currProduct);
                }
            }
        });
        acc.Salted = saltedProducts;
        acc.Frozen = frozenProducts;
        return acc;
    }, {});
}
export {
    toValueAndLabel,
    parseProducts,
    getSpecies,
    getTanningTypes,
    getTanningTypeName,
    getPreparationTypes,
    getFinishes,
    getSpeciesItems,
    getSpeciesItem,
    getSpeciesItemInventoryId,
    parseProductsForReverseInventoryIdLookup,
    parseProductsForReversePrepTypeLookup,
};
